// index.js

import { Elm } from './src/Main.elm'
import './scss/custom.scss'

let app = Elm.Main.init({
    node: document.querySelector('main')
})

app.ports.saveState.subscribe(function(json) {
    localStorage.setItem('state', json);
});

app.ports.loadState.subscribe(function() {
    let maybeState = localStorage.getItem('state');
    let state = (maybeState) ? maybeState : "{}";
    app.ports.loadStateUpdate.send(state);
});
